import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import TrainList from "./components/TrainList";
import TrainsHeading from "./components/TrainsHeading";

function App() {
  const [trains, setTrains] = useState([
    {
      id: "0",
      station: "Antwerp-Central",
      stationinfo: {
        "@id": "http://irail.be/stations/NMBS/008821006",
        id: "BE.NMBS.008821006",
        name: "Antwerp-Central",
        locationX: "4.421101",
        locationY: "51.2172",
        standardname: "Antwerpen-Centraal",
      },
      time: "1727080320",
      delay: "1260",
      canceled: "0",
      left: "0",
      isExtra: "0",
      vehicle: "BE.NMBS.IC4509",
      vehicleinfo: {
        name: "BE.NMBS.IC4509",
        shortname: "IC 4509",
        number: "4509",
        type: "IC",
        locationX: "0",
        locationY: "0",
        "@id": "http://irail.be/vehicle/IC4509",
      },
      platform: "11",
      platforminfo: {
        name: "11",

        normal: "1",
      },
      occupancy: {
        "@id": "http://api.irail.be/terms/low",
        name: "low",
      },
      departureConnection:
        "http://irail.be/connections/8812005/20240923/IC4509",
    },
    {
      id: "1",
      station: "Antwerp-Central",
      stationinfo: {
        "@id": "http://irail.be/stations/NMBS/008821006",
        id: "BE.NMBS.008821006",
        name: "Antwerp-Central",
        locationX: "4.421101",
        locationY: "51.2172",
        standardname: "Antwerpen-Centraal",
      },
      time: "1727080680",
      delay: "0",
      canceled: "0",
      left: "0",
      isExtra: "0",
      vehicle: "BE.NMBS.IC3310",
      vehicleinfo: {
        name: "BE.NMBS.IC3310",
        shortname: "IC 3310",
        number: "3310",
        type: "IC",
        locationX: "0",
        locationY: "0",
        "@id": "http://irail.be/vehicle/IC3310",
      },
      platform: "11",
      platforminfo: {
        name: "11",
        normal: "1",
      },
      occupancy: {
        "@id": "http://api.irail.be/terms/low",
        name: "low",
      },
      departureConnection:
        "http://irail.be/connections/8812005/20240923/IC3310",
    },
  ]);
  const url =
    "https://api.irail.be/v1/liveboard/?id=BE.NMBS.008812005&arrdep=departure&lang=nl&format=json&alerts=false";

  async function fetchData() {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setTrains(data.departures.departure));
  }

  useEffect(() => {
    let timerId = setInterval(() => {
      fetchData();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className="body">
      <TrainsHeading></TrainsHeading>
      <div className="container-fluid">
        <div className="row">
          <TrainList trains={trains}></TrainList>
        </div>
      </div>
    </div>
  );
}

export default App;
