import React from "react";

function TrainsHeading(props) {
  var currentTime = new Date().toLocaleTimeString();
  return (
    <div class="row trainsheading">
      <h2 class="col col-lg-2" className="text-alerts">
        using IRAIL api
      </h2>
      <h1 class="col col-lg-10 text-center">
        Het Fluvius DVDA trein-aanbod - {currentTime}
      </h1>
    </div>
  );
}

export default TrainsHeading;
