import React from "react";
import TrainCard from "./TrainCard";

function TrainList(props) {
  return (
    <>
      {props.trains.map((train, index) => (
        <div className="card d-flex justify-content-start col-3">
          <TrainCard train={train} />
        </div>
      ))}
    </>
  );
}

export default TrainList;
