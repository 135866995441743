import React from "react";

const TrainCard = ({ train }) => {
  var vertrektijd = new Date(Number(train.time) * 1000).toLocaleTimeString();

  return (
    <div class="card">
      <div class="card-body">
        <div class="row">
          <h2 class="col text-primary">{train.station}</h2>
          <h2 class="col">@ perron {train.platform}</h2>
        </div>

        <div class="row">
          <h3 class="col">vertrek {vertrektijd}</h3>
          {train.delay > 0 && (
            <h3 class="col text-danger">vertraging {train.delay / 60} min</h3>
          )}
          {!train.canceled && <h3 class="col text-danger">afgeschaft</h3>}
        </div>
      </div>
    </div>
  );
};

export default TrainCard;
